<template>
    <div>
        <h1>Houses View</h1>
    </div>

</template>

<script>
export default {
  name: "HousesView"
}
</script>

<style scoped>

</style>